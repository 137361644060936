import { useState, useEffect } from "react";
import { useFormTranslation } from "../../../hooks/commonHooks";
import { IFormKeyObject, IFormOptions } from "../../../model/formFlowModels";
import { PureCheckBox, QTextBox, RadioSelect, SelectBox } from "./Controls";
import "./AddressUS.css";

export interface AddressProps {
  fieldValue: IFormKeyObject;
  onTextChange: (value: string, fieldKey?: string) => string;
  onOptionChange: (value: string, fieldKey?: string, options?: IFormOptions[]) => void;
  className?: string;
  disabledFields?: { [key: string]: string };
}

export function Address(props: AddressProps) {
  const { wt } = useFormTranslation();
  const { street, aptSteFlr, aptSteFlrNumber, cityOrTown, state, zipCode, province, country, postalCode } =
    props.fieldValue;

  const isOutside =
    props.fieldValue.state?.value === "N/A" ||
    props.fieldValue.zipCode?.value === "N/A" ||
    (props.fieldValue.province?.value !== "" && props.fieldValue.province?.value !== "N/A") ||
    (props.fieldValue.postalCode?.value !== "" && props.fieldValue.postalCode?.value !== "N/A") ||
    (props.fieldValue.country?.value !== "" && props.fieldValue.country?.value !== "N/A");

  const handleCheckBoxChange = (checked: boolean) => {
    if (checked) {
      props.onOptionChange("N/A", state.key);
      props.onTextChange("N/A", zipCode.key);
      props.onTextChange("", postalCode.key);
      props.onTextChange("", country.key);
      props.onTextChange("", province.key);
    } else {
      props.onOptionChange("", state.key);
      props.onTextChange("", zipCode.key);
      props.onTextChange("N/A", postalCode.key);
      props.onTextChange("N/A", country.key);
      props.onTextChange("N/A", province.key);
    }
  };

  return (
    <div className="address-us-container">
      <div className="street-container">
        <QTextBox
          placeholder={wt(street.placeholder)}
          value={street.value}
          onChange={value => props.onTextChange(value, street.key)}
          disabled={props.disabledFields?.[street.key] === "false"}
        />
      </div>
      <div className="horizontal-3">
        <div className="sub-field">
          <RadioSelect
            className={aptSteFlr.className}
            onChange={value => props.onOptionChange(value, aptSteFlr.key, aptSteFlr.options)}
            options={aptSteFlr.options || ""}
            value={aptSteFlr.value}
            disabled={props.disabledFields?.[aptSteFlr.key.split(",")[0]] === "false"}
          />
        </div>
        <div className="sub-field">
          <QTextBox
            placeholder={wt(aptSteFlrNumber.placeholder)}
            value={aptSteFlrNumber.value}
            onChange={value => props.onTextChange(value, aptSteFlrNumber.key)}
            disabled={props.disabledFields?.[aptSteFlrNumber.key] === "false"}
          />
        </div>
        <div className="sub-field">
          <QTextBox
            placeholder={wt(cityOrTown.placeholder)}
            value={cityOrTown.value}
            onChange={value => props.onTextChange(value, cityOrTown.key)}
            disabled={props.disabledFields?.[cityOrTown.key] === "false"}
          />
        </div>
      </div>
      <div className="horizontal-3">
        <div className="sub-field">
          <SelectBox
            placeholder={wt(state.placeholder)}
            onChange={value => props.onOptionChange(value, state.key, state.options)}
            options={state.options || ""}
            value={state.value}
            disabled={isOutside || props.disabledFields?.[state.key.split(",")[0]] === "false"}
          />
        </div>
        <div className="sub-field">
          <QTextBox
            placeholder={wt(zipCode.placeholder)}
            value={zipCode.value}
            onChange={value => props.onTextChange(value, zipCode.key)}
            disabled={isOutside || props.disabledFields?.[zipCode.key] === "false"}
          />
        </div>
        <div className="sub-field">
          <PureCheckBox label="This address is outside the U.S." onChange={handleCheckBoxChange} checked={isOutside} />
        </div>
      </div>
      <div className="horizontal-3">
        <div className="sub-field">
          <QTextBox
            placeholder={wt(province.placeholder)}
            value={province.value}
            onChange={value => props.onTextChange(value, province.key)}
            disabled={!isOutside || props.disabledFields?.[province.key] === "false"}
          />
        </div>
        <div className="sub-field">
          <SelectBox
            placeholder={wt(country.placeholder)}
            onChange={value => props.onOptionChange(value, country.key, country.options)}
            options={country.options || ""}
            value={country.value}
            disabled={!isOutside || props.disabledFields?.[country.key.split(",")[0]] === "false"}
          />
        </div>
        <div className="sub-field">
          <QTextBox
            placeholder={wt(postalCode.placeholder)}
            value={postalCode.value}
            onChange={value => props.onTextChange(value, postalCode.key)}
            disabled={!isOutside || props.disabledFields?.[postalCode.key] === "false"}
          />
        </div>
      </div>
    </div>
  );
}
